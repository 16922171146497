import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Absolute,
  ActionButton,
  ActionButtonSkeleton,
  Flex,
  IconButton,
  ProgressStep,
  ProgressSteps,
  Relative,
  Text,
  Token,
} from '@revolut/ui-kit'
import {
  FinalGrade,
  PerformanceSelector,
  ReviewCategory,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import { canAddTeamKpi, selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  getTimelineStepDescription,
  getTimelineStepDescriptionWithDistance,
  getTimelineStepState,
} from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useCanViewMeetingsTab } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { NominateForPromotion } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/NominateForPromotion'
import { ViewNominationButton } from '@src/pages/EmployeeProfile/Preview/PerformanceSummary/PerformanceTimeline/components/ViewNominationButton'
import { StepReviewersList } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/StepReviewersList'
import { Statuses } from '@src/interfaces'
import { SectionLoader } from '@src/pages/EmployeeProfile/Layout/Performance/SectionLoader'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { captureException } from '@sentry/react'
import { createDraftGoal } from '@src/api/goals'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { useGetSelectors } from '@src/api/selectors'
import { GoalContentType } from '@src/interfaces/goals'
import { selectorKeys } from '@src/constants/api'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { PerfomanceStepDetails } from './PerfomanceStepDetails'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useNewPerformanceSummary } from './useNewPerformanceSummary'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { useFetchPerformanceSummary } from '../../Preview/Performance/Summary/hooks'

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
  Continue = 'Continue',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Review',
  [ButtonStates.Edit]: 'Edit Review',
  [ButtonStates.Continue]: 'Continue Review',
}

const ButtonIcon = {
  [ButtonStates.Add]: 'Plus' as const,
  [ButtonStates.Edit]: 'Pencil' as const,
  [ButtonStates.Continue]: 'Pencil' as const,
}

interface Props {
  data: EmployeeInterface
  selectedPeriod: PerformanceSelector
  performanceLink?: string
  finalGrade?: FinalGrade
  performanceSelectorData?: PerformanceSelector[]
  setCycleTimelineEvents?: (events: SingleTimelineEventInterface[]) => void
  eventsData?: SingleTimelineEventInterface[]
  isLoading?: boolean
}

export const PerformanceTimeline = ({
  data,
  selectedPeriod,
  performanceLink,
  finalGrade,
  performanceSelectorData,
  setCycleTimelineEvents,
  eventsData,
  isLoading,
}: Props) => {
  const currentCycleReviewsPublishingDay = performanceSelectorData?.find(
    ({ id }) => id === selectedPeriod.id,
  )?.reviews_publishing_day

  const isNewDesign = useNewPerformanceSummary()

  const { data: settings } = useGetPerformanceSettings()
  const [isCreateGoalPending, setIsCreateGoalPending] = useState(false)
  const [selectedStepId, setSelectedStepId] = useState<string>()

  const { navigateWithEntity } = useOrgEntity()
  const { data: summaryData, isLoading: summaryLoading } = useFetchPerformanceSummary(
    selectedPeriod?.id !== undefined ? String(selectedPeriod?.id) : undefined,
    data.id,
    ReviewCategory.Performance,
  )

  const { data: contentTypes, isLoading: contentTypesLoading } =
    useGetSelectors<GoalContentType>(selectorKeys.goal_content_types)
  const showStatusPopup = useShowStatusPopup()
  const canAddKpi = useSelector(canAddTeamKpi)
  const canViewMeetings = useCanViewMeetingsTab(data)
  const user = useSelector(selectUser)
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  const canAddGoals = !!data.field_options?.permissions?.includes(
    PermissionTypes.CanAddGoals,
  )

  useEffect(() => {
    if (setCycleTimelineEvents) {
      setCycleTimelineEvents(eventsData || [])
    }
  }, [eventsData])

  const timelineEvents = Array.isArray(eventsData) ? eventsData : []

  const initialAccumulator = {
    timelineEventsWithoutResults: [] as SingleTimelineEventInterface[],
    lastStepData: [] as SingleTimelineEventInterface[],
    lastStepState: 'done' as 'done' | 'default' | 'pending',
    lastStepDescription: null as JSX.Element | null,
    timeLineEventsMap: new Map() as Map<
      SingleTimelineEventInterface['title'],
      SingleTimelineEventInterface
    >,
  }

  const {
    timelineEventsWithoutResults,
    lastStepState,
    lastStepDescription,
    lastStepData,
    timeLineEventsMap,
  } = useMemo(() => {
    if (!timelineEvents) {
      return initialAccumulator
    }

    return timelineEvents.reduce<typeof initialAccumulator>((acc, curr) => {
      acc.timeLineEventsMap.set(curr.title, curr)

      if (curr.category === 'review_results') {
        acc.lastStepData.push(curr)
        const currentState = getTimelineStepState(
          curr.start_period,
          curr.end_period,
          curr.category,
        )

        if (currentState === 'pending' && acc.lastStepState !== 'pending') {
          acc.lastStepState = 'pending'
        } else if (currentState === 'default' && acc.lastStepState === 'done') {
          acc.lastStepState = 'default'
        }

        if (acc.lastStepData.length === 1 || acc.lastStepData.length === 2) {
          acc.lastStepDescription = getTimelineStepDescription(curr)
        }
      } else {
        acc.timelineEventsWithoutResults.push(curr)
      }
      return acc
    }, initialAccumulator)
  }, [timelineEvents])

  const pendingTimeLineStepData = useMemo(() => {
    const targetEvent = timelineEvents?.filter(event => {
      const state = getTimelineStepState(
        event.start_period,
        event.end_period,
        event.category,
      )
      return state === 'pending'
    })[0]
    return { type: targetEvent?.category, event: targetEvent }
  }, [timelineEvents])

  useEffect(() => {
    if (lastStepState === 'pending') {
      setSelectedStepId('Grade published')
    }

    if (pendingTimeLineStepData.event) {
      setSelectedStepId(pendingTimeLineStepData.event.title)
    }
  }, [pendingTimeLineStepData])

  const activeTimeLineStep = useMemo(() => {
    if (selectedStepId === 'Grade published') {
      return { type: lastStepData[0]?.category, event: undefined }
    }

    if (selectedStepId) {
      const event = timeLineEventsMap.get(selectedStepId)
      return { type: event?.category, event }
    }

    return pendingTimeLineStepData
  }, [selectedStepId, pendingTimeLineStepData])

  if (isLoading || summaryLoading) {
    return <SectionLoader />
  }

  if (!timelineEvents) {
    return null
  }

  const onAddGoalsClick = async () => {
    if (goalsEnabled) {
      try {
        setIsCreateGoalPending(true)
        const response = await createDraftGoal({
          owner: { id: data.id },
          content_type: contentTypes?.find(({ model }) => model === 'employees'),
          object_id: data.id,
        })
        navigateWithEntity(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: response.data.id }), {
          reviewCycleId: selectedPeriod.id,
          isNew: true,
        })
      } catch (err) {
        captureException(err)

        showStatusPopup({
          status: 'error',
          title: 'Failed to create goal',
          description: getStringMessageFromError(
            err,
            'Something went wrong. Please try again.',
          ),
        })
      } finally {
        setIsCreateGoalPending(false)
      }
    } else {
      navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL), {
        initialValues: getKPIFormInitialValues(
          {
            id: data.id,
            name: data.full_name,
            team: {
              // @ts-ignore FIXME: REVPI-19 support optional team value
              id: data.team.id,
              // @ts-ignore FIXME: REVPI-19 support optional team value
              name: data.team.name,
            },
          },
          { is_employee: true },
        ),
      })
    }
  }

  const renderButton = (category: string | undefined) => {
    const Wrapper = isNewDesign ? React.Fragment : ProgressStep.Side
    const isKPICategoryAddGoal = category === 'kpi' && canAddKpi
    const isGoalCategoryAddGoal = category === 'goal' && goalsEnabled && canAddGoals
    if (
      selectedPeriod?.status === ReviewCycleStatus.ongoing &&
      (isKPICategoryAddGoal || isGoalCategoryAddGoal)
    ) {
      return (
        <Wrapper>
          {contentTypesLoading ? (
            <ActionButtonSkeleton />
          ) : (
            <ActionButton
              useIcon="Plus"
              onClick={onAddGoalsClick}
              pending={isCreateGoalPending}
            >
              Add goals
            </ActionButton>
          )}
        </Wrapper>
      )
    }

    if (category === 'meeting') {
      return (
        <Wrapper>
          <ActionButton
            useIcon="Plus"
            onClick={() => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.FEEDBACK.ONE_TO_ONE, {
                  userId: data.id,
                }),
              )
            }}
          >
            Add note
          </ActionButton>
        </Wrapper>
      )
    }

    if (category === 'promotion') {
      return (
        <Wrapper>
          <NominateForPromotion
            text="Nominate"
            icon="Plus"
            data={data}
            cycleId={selectedPeriod.id}
          />
          <ViewNominationButton data={data} cycleId={selectedPeriod.id} />
        </Wrapper>
      )
    }

    return null
  }

  const renderPerformanceReviewers = (
    event: SingleTimelineEventInterface | undefined,
    eventState?: string,
  ) => {
    let buttonDisabled
    if (!event?.reviews?.length) {
      if ((event?.category === 'kpi' || event?.category === 'goal') && isNewDesign) {
        return (
          <Flex justifyContent="space-between" alignItems="center">
            <Text variant="emphasis1">Performance goals pending</Text>
            {renderButton(event.category)}
          </Flex>
        )
      }
      return null
    }

    const currentUserReview = event.reviews.find(
      review => review.reviewer?.id === user.id,
    )
    if (isNewDesign) {
      buttonDisabled = currentUserReview ? !currentUserReview.can_submit : true
    } else {
      buttonDisabled = currentUserReview?.can_submit
        ? false
        : eventState !== 'pending' || !currentUserReview
    }

    let state = ButtonStates.Add
    if (currentUserReview?.status === Statuses.completed) {
      state = ButtonStates.Edit
    }
    if (currentUserReview?.status === Statuses.draft) {
      state = ButtonStates.Continue
    }

    const reviewLink = ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT

    const isGoalsStep = event.category === 'kpi' || event.category === 'goal'
    const isCalibrationStep = event.category === 'calibration'
    const subText = isGoalsStep ? 'Set quarterly goals' : ''
    return isNewDesign ? (
      <StepReviewersList
        isNewDesign
        summaryData={summaryData}
        isCalibrationStep={isCalibrationStep}
        reviewsPublishingDay={currentCycleReviewsPublishingDay}
        isProfileLink
        secondaryActionButton={renderButton(event.category)}
        reviews={event.reviews}
        subText={subText}
        onClickAddReview={e => {
          e.stopPropagation()
          navigateTo(
            pathToUrl(reviewLink, {
              id: currentUserReview?.id,
              employeeId: data.id,
            }),
          )
        }}
        buttonDisabled={buttonDisabled}
        icon={ButtonIcon[state]}
        title={ButtonTitles[state]}
        performanceLink={isCalibrationStep ? undefined : performanceLink}
      />
    ) : (
      <ProgressStep.Description>
        <StepReviewersList
          reviews={event.reviews}
          onClickAddReview={e => {
            e.stopPropagation()
            navigateTo(
              pathToUrl(reviewLink, {
                id: currentUserReview?.id,
                employeeId: data.id,
              }),
            )
          }}
          buttonDisabled={buttonDisabled}
          icon={ButtonIcon[state]}
          title={ButtonTitles[state]}
          performanceLink={performanceLink}
        />
      </ProgressStep.Description>
    )
  }

  const events = isNewDesign ? timelineEventsWithoutResults : timelineEvents

  return (
    <>
      <ProgressSteps variant={isNewDesign ? 'default' : 'vertical-compact'}>
        {events.length > 0 &&
          events.map((event, i) => {
            if (event.category === 'meeting' && !canViewMeetings) {
              return null
            }

            const state = getTimelineStepState(
              event.start_period,
              event.end_period,
              event.category,
            )
            const description = isNewDesign
              ? getTimelineStepDescription(event)
              : getTimelineStepDescriptionWithDistance(event)
            const shouldRenderStep =
              event.category === 'calibration' ? settings?.enable_calibration : true
            if (!isNewDesign && shouldRenderStep) {
              return (
                <ProgressStep
                  data-testid={`${event.title}--${state}`}
                  key={i}
                  state={state}
                >
                  <ProgressStep.Title>{event.title}</ProgressStep.Title>
                  <ProgressStep.Description>{description}</ProgressStep.Description>
                  {event.category === 'review' &&
                    renderPerformanceReviewers(event, state)}
                  {renderButton(event.category)}
                </ProgressStep>
              )
            }

            if (isNewDesign && shouldRenderStep && event.category !== 'meeting') {
              return (
                <ProgressStep
                  indicatorColor={
                    state === 'pending' ? Token.color.accent : Token.color.teal
                  }
                  style={{ cursor: 'pointer' }}
                  data-testid={`${event.title}--${state}`}
                  key={i}
                  state={state}
                  onClick={() => setSelectedStepId(event.title)}
                  paddingTop="s-6"
                  paddingBottom="s-16"
                  borderRadius="r6"
                  bg={selectedStepId === event.title ? Token.color.grey50_10 : undefined}
                >
                  {event.title && (
                    <ProgressStep.Title>
                      {event.category === 'promotion'
                        ? 'Nomination for promotion'
                        : event.title}
                    </ProgressStep.Title>
                  )}
                  <ProgressStep.Description>{description}</ProgressStep.Description>
                </ProgressStep>
              )
            }
            return null
          })}

        {isNewDesign && (
          <ProgressStep
            indicatorColor={
              lastStepState === 'pending' ? Token.color.accent : Token.color.teal
            }
            style={{ cursor: 'pointer' }}
            data-testid={`Grade published--${lastStepState}`}
            state={lastStepState}
            onClick={() => setSelectedStepId('Grade published')}
            paddingTop="s-6"
            paddingBottom="s-16"
            borderRadius="r6"
            bg={
              selectedStepId === 'Grade published' || lastStepState === 'pending'
                ? Token.color.grey50_10
                : undefined
            }
          >
            <ProgressStep.Title>Grade published</ProgressStep.Title>
            <ProgressStep.Description>{lastStepDescription}</ProgressStep.Description>
          </ProgressStep>
        )}
      </ProgressSteps>
      {selectedStepId && activeTimeLineStep?.type && isNewDesign && (
        <Relative>
          <Flex
            mt="s-12"
            borderRadius="r16"
            padding="s-16"
            bg={Token.color.grey50_10}
            flexDirection="column"
          >
            <Absolute top=".5rem" right=".5rem" zIndex={100}>
              <IconButton
                onClick={() => setSelectedStepId(undefined)}
                useIcon="Cross"
                size={16}
                mb="s-2"
              />
            </Absolute>
            <PerfomanceStepDetails
              isEmptyReviewers={!activeTimeLineStep.event?.reviews?.length}
              publishedResults={lastStepData}
              finalGrade={finalGrade}
              type={activeTimeLineStep?.type}
              eventStartDate={activeTimeLineStep.event?.start_period}
              eventEndDate={activeTimeLineStep.event?.end_period}
              reviewsElements={renderPerformanceReviewers(activeTimeLineStep.event)}
              actionsElements={renderButton(activeTimeLineStep.type)}
            />
          </Flex>
        </Relative>
      )}
    </>
  )
}
