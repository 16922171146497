import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import { RecommendationQuestionInterface } from './BarRaiserSummary'
import { OptionInterface } from '@src/interfaces/selectors'
import { RecommendationExtraSectionQuestionInterface } from './BarRaiserSummaryCollapsable'
import {
  performanceSummaryBarRaiserReviewerColumn,
  performanceSummarySkillsNameColumn,
} from '@src/constants/columns/performanceSummary'
import {
  getBarRaiserGradeColor,
  KeeperGrade,
  KeeperGradeToString,
  ReviewerRelation,
} from '@src/interfaces/performance'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Avatar, Badge, Box, Flex, Text, Token } from '@revolut/ui-kit'
import { getAvatarUrl, getInitials } from '@src/utils/employees'
import { PeerReviewsWithTooltip } from './PeerReviewsWithTooltip'

const getConntentByRelation = (
  data: RecommendationExtraSectionQuestionInterface,
  targetRelation: ReviewerRelation,
  checkpointFilters: OptionInterface[] | undefined,
) => {
  const lmReviewAnswers = data.reviews?.filter(({ relation, checkpoint_number }) => {
    const isTargetRelation = relation === targetRelation

    if (!checkpointFilters) {
      return isTargetRelation
    }

    const checkpointFiltersIds = checkpointFilters.map(({ id }) => id)
    return (
      isTargetRelation &&
      checkpoint_number &&
      checkpointFiltersIds.includes(checkpoint_number)
    )
  })

  const agregatedQuestionsAndCheckpoints = lmReviewAnswers?.reduce<
    Record<
      string,
      {
        answer?: KeeperGrade | null
        checkpoint?: number
        reviewer?: EmployeeOptionInterface
      }[]
    >
  >((acc, item) => {
    const targetQuestion = item.recommendation?.find(({ title }) => title === data.title)
    const questionEntity = {
      answer: targetQuestion?.value,
      checkpoint: item.checkpoint_number,
      reviewer: item.reviewer,
    }
    if (data.title && acc[data.title]) {
      acc[data.title] = [...acc[data.title], questionEntity]
    } else if (data.title) {
      acc[data.title] = [questionEntity]
    }
    return acc
  }, {})

  const answersArray = data.title ? agregatedQuestionsAndCheckpoints?.[data.title] : []

  return answersArray?.length ? (
    <Box>
      {answersArray?.map((item, index) => {
        return (
          <Flex pb="s-6" key={index} alignItems="center" gap="s-8">
            <Avatar
              image={getAvatarUrl(item.reviewer?.avatar)}
              size={checkpointFilters ? 32 : 24}
              uuid={item?.reviewer?.full_name}
              label={item?.reviewer?.full_name && getInitials(item?.reviewer?.full_name)}
            />
            <Flex flexDirection="column" gap="s-6">
              <Flex key={index} gap="s-2">
                {item.answer && (
                  <Text variant="emphasis2" color={getBarRaiserGradeColor(item.answer)}>
                    {KeeperGradeToString[item.answer]}
                  </Text>
                )}
                {!checkpointFilters && (
                  <Badge bg={Token.color.greyTone10} color="foreground">
                    CP{item.checkpoint}
                  </Badge>
                )}
              </Flex>
            </Flex>
          </Flex>
        )
      })}
    </Box>
  ) : (
    '-'
  )
}

export const getRowRecommendationExtraSection: (
  checkpointFilters: OptionInterface[] | undefined,
) => RowInterface<RecommendationExtraSectionQuestionInterface> = checkpointFilters => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      title: '',
      width: 100,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        return getConntentByRelation(
          data,
          ReviewerRelation.LineManager,
          checkpointFilters,
        )
      },
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        return getConntentByRelation(
          data,
          ReviewerRelation.FunctionalManager,
          checkpointFilters,
        )
      },
      title: 'FM',
      width: 60,
    },
  ],
})

export const getRow: (
  completedReviews: boolean,
) => RowInterface<RecommendationQuestionInterface> = completedReviews => ({
  cells: [
    {
      ...performanceSummarySkillsNameColumn,
      title: '',
      width: 100,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const lmReview = data.reviews?.find(review => review.type === 'lm')
        const questionValue =
          lmReview?.recommendation.keeper_test_section.questions[data.questionIdx].value

        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        const reviewerData = lmReview?.recommendation.reviewer
        return lmReview && reviewerData ? (
          <Flex alignItems="center" gap="s-8">
            <Avatar
              image={getAvatarUrl(reviewerData.avatar)}
              size={32}
              uuid={reviewerData.full_name}
              label={reviewerData.full_name && getInitials(reviewerData.full_name)}
            />
            <Text variant="emphasis2" color={getBarRaiserGradeColor(questionValue)}>
              {gradeValue}
            </Text>
          </Flex>
        ) : (
          '-'
        )
      },
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const fmReview = data.reviews?.find(review => review.type === 'fm')

        const questionValue =
          fmReview?.recommendation.keeper_test_section.questions[data.questionIdx].value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        const reviewerData = fmReview?.recommendation.reviewer

        return fmReview && reviewerData ? (
          <Flex alignItems="center" gap="s-8">
            <Avatar
              image={getAvatarUrl(reviewerData.avatar)}
              size={32}
              uuid={reviewerData.full_name}
              label={reviewerData.full_name && getInitials(reviewerData.full_name!)}
            />
            <Text variant="emphasis2" color={getBarRaiserGradeColor(questionValue)}>
              {gradeValue}
            </Text>
          </Flex>
        ) : (
          '-'
        )
      },
      title: 'FM',
      width: 60,
    },
    {
      ...performanceSummaryBarRaiserReviewerColumn,
      insert: ({ data }) => {
        const peerReviews = data.reviews?.filter(review => review.type === 'peer')
        const questionValue =
          peerReviews?.[0]?.recommendation.keeper_test_section.questions[data.questionIdx]
            .value
        const gradeValue = questionValue ? KeeperGradeToString[questionValue] : '-'
        return completedReviews ? (
          <PeerReviewsWithTooltip
            isNewDesign
            reviews={peerReviews}
            questionIdx={data.questionIdx}
          />
        ) : (
          gradeValue
        )
      },
      title: 'Peer',
      width: 60,
    },
  ],
})
