import React from 'react'
import { TextWidget, Token, VStack, Widget } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import NewRadioButtons from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { calibrationLogicOptions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/EditCalibrationLogic/CalibrationLogicOptions'

export const EditFormContent = connect(() => {
  const { values } = useLapeContext<ReviewCyclesInterface>()

  const { grade_calculation_method: calibrationMethod } = values
  const selectedOption = calibrationLogicOptions.find(
    ({ value: { id } }) => id === calibrationMethod,
  )

  return (
    <Widget>
      <TextWidget>
        <TextWidget.Title>Performance grade logic</TextWidget.Title>
        <TextWidget.Content color={Token.color.greyTone50}>
          Settings that define the grade calculation logic for all future review cycles
        </TextWidget.Content>
      </TextWidget>
      <VStack p="s-16">
        <NewRadioButtons
          variant="group-items"
          direction="column"
          highlightSelected
          value={selectedOption?.value}
          options={calibrationLogicOptions}
          onChange={({ value: { id } }) => {
            values.grade_calculation_method = id
          }}
        />
      </VStack>
    </Widget>
  )
})
