import { Avatar, Group, Icon, Item, ItemSkeleton, Text, Token } from '@revolut/ui-kit'
import React, { useEffect } from 'react'
import {
  getFormattedCycleName,
  getFormattedCycleNameForPendingCycle,
  getPeriodCategoryBadgeContent,
} from './utils'
import { usePerformanceTimelineTable } from '../../Preview/Performance/PerformanceHistory/hooks'
import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import {
  PerformanceSelector,
  PerformanceTimelineInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import { getInverseColorsAndGrade } from '@src/utils/grades'
import { PerformanceChartGradeData } from '@src/interfaces/chart'
import { PerformanceSenioritySublevel } from '@src/constants/performance'

interface Props {
  data: EmployeeInterface
  setSelectedPeriod: React.Dispatch<React.SetStateAction<PerformanceSelector | undefined>>
  performanceSelectorData: PerformanceSelector[] | undefined
  currentCycle: PerformanceSelector | undefined
  selectedPeriod: PerformanceSelector | undefined
  setSelectedCycle: (cycle: PerformanceTimelineInterface | undefined) => void
  chartDataGradesValues: PerformanceChartGradeData<number>[]
}

const PROBATION_EXTENSION = 'Probation - Extension'
const PIP_EXTENSION = 'PIP - Extension'

export const PerformanceHistoryNavigation = ({
  data,
  setSelectedPeriod,
  performanceSelectorData,
  currentCycle,
  selectedPeriod,
  chartDataGradesValues,
  setSelectedCycle,
}: Props) => {
  const performanceTimelineTable = usePerformanceTimelineTable(data)

  const probationExtendedItem = performanceSelectorData?.find(
    ({ name }) => name === PROBATION_EXTENSION,
  )

  const pipExtensionItem = performanceSelectorData?.find(
    ({ name }) => name === PIP_EXTENSION,
  )

  const getFormattedCycle = (item: PerformanceTimelineInterface) => {
    if (item.category === ReviewCategory.Probation && probationExtendedItem) {
      return {
        ...item.cycle,
        id:
          performanceSelectorData?.find(selector => selector.name === 'Probation')?.id ||
          item.cycle.id,
      }
    }

    if (item.category === ReviewCategory.PIP && pipExtensionItem) {
      return {
        ...item.cycle,
        id:
          performanceSelectorData?.find(selector => selector.name === 'PIP')?.id ||
          item.cycle.id,
      }
    }
    return item.cycle
  }

  const performanceTimelineTableWithoutCurrent = performanceTimelineTable.table?.data
    .filter(item => item.cycle?.id !== currentCycle?.id)
    .map(item => {
      return {
        ...item,
        cycle: getFormattedCycle(item),
      }
    })

  const probationGrade = chartDataGradesValues.find(
    item => item.progress_datetime_label === 'Probation',
  )?.progress

  useEffect(() => {
    if (
      data.status.id === IdStatuses.terminated &&
      performanceTimelineTableWithoutCurrent?.length
    ) {
      const targetPeriod = performanceSelectorData?.find(
        selector => selector.id === performanceTimelineTableWithoutCurrent[0].cycle.id,
      )
      setSelectedPeriod(targetPeriod)
    }
  }, [performanceTimelineTableWithoutCurrent])

  const renderSeparateCycle = (cycle: PerformanceSelector) => {
    return (
      <Item
        key={cycle.id}
        use="button"
        variant="compact"
        aria-pressed={selectedPeriod?.id === cycle.id}
        onClick={() => {
          setSelectedPeriod(probationExtendedItem)
        }}
      >
        <Item.Avatar>
          <Avatar color={Token.color.foreground} size={40} textStyle="emphasis1">
            {selectedPeriod?.id === cycle.id && (
              <Avatar.Badge
                bg={Token.color.foreground}
                position="bottom-right"
                useIcon={<Icon name="Check" size={12} />}
                size={16}
              />
            )}
            {getPeriodCategoryBadgeContent(cycle.category, cycle.name)}
          </Avatar>
        </Item.Avatar>
        <Item.Content>
          <Item.Description>{cycle.name}</Item.Description>
        </Item.Content>
      </Item>
    )
  }

  const renderPIPExtensionCycle = () => {
    if (!pipExtensionItem) {
      return <></>
    }

    return renderSeparateCycle(pipExtensionItem)
  }

  const renderProbationExtensionCycle = () => {
    if (!probationExtendedItem) {
      return <></>
    }

    return renderSeparateCycle(probationExtendedItem)
  }

  const renderCurrentCycle = () => {
    const currentEmpoyeeSeniority = data.specialisation_seniority_sublevel?.name || ''
    if (!currentCycle) {
      return <></>
    }
    return (
      <Item
        use="button"
        variant="compact"
        aria-pressed={selectedPeriod?.id === currentCycle.id}
        onClick={() => {
          setSelectedPeriod(currentCycle)
          setSelectedCycle(undefined)
        }}
      >
        <Item.Avatar>
          <Avatar color={Token.color.foreground} size={40} textStyle="emphasis1">
            {selectedPeriod?.id === currentCycle.id && (
              <Avatar.Badge
                bg={Token.color.foreground}
                position="bottom-right"
                useIcon={<Icon name="Check" size={12} />}
                size={16}
              />
            )}
            {getPeriodCategoryBadgeContent(currentCycle.category, currentCycle.name)}
          </Avatar>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{currentEmpoyeeSeniority}</Item.Title>
          <Item.Description>
            {getFormattedCycleNameForPendingCycle(currentCycle)}
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <Text color={Token.color.greyTone50} variant="body1">
              Pending
            </Text>
          </Item.Value>
        </Item.Side>
      </Item>
    )
  }

  return (
    <Group>
      {performanceTimelineTable.table?.loading && (
        <>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </>
      )}
      {!performanceTimelineTable.table?.loading &&
        currentCycle &&
        data.status.id !== IdStatuses.terminated &&
        renderCurrentCycle()}

      {performanceTimelineTableWithoutCurrent?.map(item => {
        const itemSeniority = item.employee_specialisation_seniority_sublevel?.name
          ? item.employee_specialisation_seniority_sublevel?.name
          : item.employee_seniority?.name && item.employee_seniority?.level
          ? `${item.employee_seniority.name} ${
              PerformanceSenioritySublevel[item.employee_seniority.level]
            }`
          : ''

        return (
          <React.Fragment key={item.id}>
            {item.category === ReviewCategory.PIP && renderPIPExtensionCycle()}
            {item.category === ReviewCategory.Probation &&
              renderProbationExtensionCycle()}
            <Item
              use="button"
              variant="compact"
              aria-pressed={selectedPeriod?.id === item.cycle.id}
              onClick={() => {
                const clickedPeriod = performanceSelectorData?.find(
                  selector => selector.id === item.cycle.id,
                )
                if (clickedPeriod) {
                  setSelectedPeriod(clickedPeriod)
                }
                setSelectedCycle(item)
              }}
            >
              <Item.Avatar>
                <Avatar color={Token.color.foreground} size={40} textStyle="emphasis1">
                  {selectedPeriod?.id === item.cycle.id && (
                    <Avatar.Badge
                      bg={Token.color.foreground}
                      position="bottom-right"
                      useIcon={<Icon name="Check" size={12} />}
                      size={16}
                    />
                  )}
                  {getPeriodCategoryBadgeContent(item.category, item.cycle?.name)}
                </Avatar>
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{itemSeniority}</Item.Title>
                <Item.Description>{getFormattedCycleName(item)}</Item.Description>
              </Item.Content>
              <Item.Side>
                <Item.Value>
                  <Text
                    color={
                      item.category === ReviewCategory.Probation
                        ? getInverseColorsAndGrade(probationGrade)?.color
                        : getInverseColorsAndGrade(item.grade)?.color
                    }
                    variant="body1"
                  >
                    {item.category === ReviewCategory.Probation
                      ? probationGrade?.label
                      : item.grade?.label}
                  </Text>
                </Item.Value>
              </Item.Side>
            </Item>
          </React.Fragment>
        )
      })}
    </Group>
  )
}
